export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const SET_USER = 'SET_USER';
export const CLEAR_USER = 'CLEAR_USER';
export const SET_LOADING = 'SET_LOADING';

/* [
  {
    month: 'Januar 2023',
    workingDays: [
      {
        date: '2022-12-31',
        dayOfWeek: 'Ned',
        startShift: '8,00',
        endShift: '16,00',
        type: 'morning shift',
      },
      {
        date: '2023-01-01',
        dayOfWeek: 'Pon',
        startShift: '8,00',
        endShift: '16,00',
        type: 'morning shift',
      },
    ],
  },
  {
    month: '2023-03',
    workingHours: [Array(26)],
  },
]; */
