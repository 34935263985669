import React, { useContext, useEffect } from 'react';

import ZaposleniciTable from '../components/ZaposleniciTable';
import authContext from '../contexts/auth/authContext';
import { auth } from '../config/config';
/* import { employee } from '../data';
import { doc, collection, writeBatch, onSnapshot } from 'firebase/firestore'; */
import globalContext from '../contexts/satnica/globalContext';

const DashBoard = () => {
  const AuthContext = useContext(authContext);
  const GlobalContext = useContext(globalContext);
  const { user } = AuthContext;

  const { displayEmployees, dispatch } = GlobalContext;

  const currentUserUid = auth.currentUser.uid;

  // Display users from firebase
  useEffect(() => {
    if (user) {
      displayEmployees(currentUserUid, dispatch);
      dispatch({ type: 'CLEAR_EMPLOYEE' });
    }
  }, [currentUserUid]);

  return (
    <div className='px-5 pt-5 pb-10'>
      <h3 className='my-5 font-semibold py-2'>Zaposlenici</h3>
      <div className='flex'>
        <ZaposleniciTable />
      </div>
      {/*  <button
        onClick={() => addEmployees(employee)}
        className='mt-5 p-1 px-3 bg-blue-500 text-white'
      >
        Add user
      </button> */}
    </div>
  );
};

export default DashBoard;
