import { sendPasswordResetEmail } from 'firebase/auth';
import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { auth } from '../config/config';
/* import { sendPasswordResetEmail } from 'firebase/auth'; */

const PasswordReset = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const { oobCode } = useParams();

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    console.log(auth);

    const actionCodeSettings = {
      url: `https://satnica-77a28.firebaseapp.com/password-reset?mode=resetPassword&oobCode=${oobCode}&lang=bs`,
    };

    try {
      await sendPasswordResetEmail(auth, email, actionCodeSettings);

      setSuccess('Link za resetovanje passworda je poslat na vaš email.');
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className='flex min-h-screen justify-center mt-[20%]'>
      <div className='flex flex-col items-center'>
        <h2 className='text-2xl mb-4'>Resetovanje Passworda</h2>
        {error && <p className='text-red-600 mb-2'>{'Greška na serveru'}</p>}
        {success && <p className='text-green-600 mb-2'>{success}</p>}
        <form
          onSubmit={handleResetPassword}
          className='flex flex-col mt-[40px]'
        >
          <input
            type='email'
            className='border rounded-md border-gray-400 outline-none focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50 p-2 text-center'
            placeholder='Unesite email adresu'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button
            type='submit'
            className='bg-blue-500 text-white py-2 px-4 rounded-md mt-4'
          >
            Resetuj Password
          </button>
        </form>
        <div className='mt-4'>
          <Link to='/' className='text-blue-500 hover:text-blue-600'>
            Povratak na login
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;
