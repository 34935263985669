import React, { useReducer, useState, useContext } from 'react';
import 'firebase/auth';
import AuthContext from './authContext';
import authReducer from './authReducer';
import { auth } from '../../config/config';
import { signInWithEmailAndPassword, updateProfile } from 'firebase/auth';
import alertContext from '../alert/alertContext';

const AuthState = ({ children }) => {
  const initialState = {
    user: null,
    loading: false,
    smallLoading: false,
    isAuthenticated: false,
  };

  const [state, dispatch] = useReducer(authReducer, initialState);

  const AlertContext = useContext(alertContext);

  const { setAlert } = AlertContext;
  const [error, setError] = useState('');

  const loginUser = async (data) => {
    const { email, password, firma, rememberMe } = data;

    console.log(data);

    dispatch({ type: 'SET_SMALL_LOADING', payload: true });
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      await updateProfile(userCredential.user, {
        displayName: firma,
      });

      if (rememberMe) {
        // Save user credentials to local storage
        localStorage.setItem('email', email);
        localStorage.setItem('password', password);
        localStorage.setItem('rememberMe', 'true');
      } else {
        // Remove user credentials from local storage
        localStorage.removeItem('email');
        localStorage.removeItem('password');
        localStorage.removeItem('rememberMe');
      }
    } catch (error) {
      dispatch({ type: 'SET_SMALL_LOADING', payload: false });
      console.log(error.code);
      if (error.code === 'auth/wrong-password') {
        setAlert(
          'Pogrešan password ili Email',
          'text-red-800 bg-red-100 dark:bg-gray-900 dark:text-red-400 font-semibold  '
        );
      } else if (error.code === 'auth/too-many-requests') {
        setAlert(
          'Previše puta pogrešan password',
          'text-red-800 bg-red-100 dark:bg-gray-900 dark:text-red-400 font-semibold  '
        );
      }
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user: state.user,
        loading: state.loading,
        isAuthenticated: state.isAuthenticated,
        smallLoading: state.smallLoading,
        dispatch,
        loginUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthState;
