import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const Contact = () => {
  const formik = useFormik({
    initialValues: {
      fullName: '',
      email: '',
      subject: '',
      message: '',
    },
    validationSchema: Yup.object({
      fullName: Yup.string().required('Obavezno polje'),
      email: Yup.string().email('Pogrešan email').required('Obavezno polje'),
      subject: Yup.string().required('Obavezno polje'),
      message: Yup.string().required('Obavezno polje'),
    }),
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  const errorStyle = {
    color: '#FF0000',
    fontSize: '12px',
    marginLeft: '10px',
    marginTop: '5px',
  };

  return (
    <div className='flex py-5 pr-[100px]'>
      <div className='w-2/3 p-4 flex flex-col items-center justify-center'>
        {/*   <div className="flex items-center my-4">
            <i class="fa-regular fa-envelope-open fa-4x"></i>
        </div> */}
        <h2 className='text-[24px]  font-normal border-1 border-b-[1px]'>
          Kontaktirajte nas
        </h2>

        <p className='text-[23px] font-normal text-gray-700 pt-1'>
          {' '}
          Broj: 061 056 710
        </p>
      </div>
      <div className='w-2/3 p-4'>
        <form onSubmit={formik.handleSubmit}>
          <div className='flex mb-4'>
            <div className='w-1/2 mr-4'>
              <label htmlFor='fullName' className='block mb-1'>
                Ime i Prezime:
              </label>
              <input
                id='fullName'
                type='text'
                className='w-full px-4 py-1 rounded-lg border focus:border-blue-500 focus:outline-none'
                {...formik.getFieldProps('fullName')}
              />
              {formik.touched.fullName && formik.errors.fullName ? (
                <div style={errorStyle}>{formik.errors.fullName}</div>
              ) : null}
            </div>
            <div className='w-1/2'>
              <label htmlFor='email' className='block mb-1'>
                Email:
              </label>
              <input
                id='email'
                type='email'
                className='w-full px-4 py-1 rounded-lg border focus:border-blue-500 focus:outline-none'
                {...formik.getFieldProps('email')}
              />
              {formik.touched.email && formik.errors.email ? (
                <div style={errorStyle}>{formik.errors.email}</div>
              ) : null}
            </div>
          </div>
          <div className='mb-4'>
            <label htmlFor='subject' className='block mb-1'>
              Predmet:
            </label>
            <input
              id='subject'
              type='text'
              className='w-full px-4 py-1 rounded-lg border focus:border-blue-500 focus:outline-none'
              {...formik.getFieldProps('subject')}
            />
            {formik.touched.subject && formik.errors.subject ? (
              <div style={errorStyle}>{formik.errors.subject}</div>
            ) : null}
          </div>
          <div className='mb-4'>
            <label htmlFor='message' className='block mb-1'>
              Poruka:
            </label>
            <textarea
              rows={5}
              id='message'
              className='w-full px-4 py-2 rounded-lg border focus:border-blue-500 focus:outline-none'
              {...formik.getFieldProps('message')}
            />
            {formik.touched.message && formik.errors.message ? (
              <div style={errorStyle}>{formik.errors.message}</div>
            ) : null}
          </div>
          <button
            type='submit'
            className='bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:outline-none'
          >
            Pošalji
          </button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
