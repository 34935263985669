import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import AuthState from './contexts/auth/AuthState';
import AlertState from './contexts/alert/AlertState';
import { SatnicaState } from './contexts/satnica/satnicaState';

const root = createRoot(document.getElementById('root'));

root.render(
  <AlertState>
    <AuthState>
      <SatnicaState>
        <Router>
          <App />
        </Router>
      </SatnicaState>
    </AuthState>
  </AlertState>
);
