import { Link } from 'react-router-dom';

export default function FormExtra({ rememberMe, setRememberMe }) {
  const handleCheckboxChange = (e) => {
    setRememberMe(e.target.checked);
  };
  return (
    <div className='flex items-center justify-between'>
      <div className='flex items-center'>
        <input
          id='remember-me'
          name='remember-me'
          type='checkbox'
          className='h-4 w-4 text-blue-500 focus:ring-blue-500 border-gray-300 rounded'
          onChange={handleCheckboxChange}
          checked={rememberMe}
        />
        <label
          htmlFor='remember-me'
          className='ml-2 block text-sm text-gray-900'
        >
          Zapamti me
        </label>
      </div>

      <div className='text-sm'>
        <Link
          to='/password-reset'
          className='font-medium text-blue-500 hover:text-blue-500'
        >
          Zaboravio si password?
        </Link>
      </div>
    </div>
  );
}
