import React, { useReducer, useContext } from 'react';
import contextusReducer from './satnicaReducer';
import GlobalContext from './globalContext';
import authContext from '../auth/authContext';

import {
  displayEmployees,
  addEmployee,
  addWorkingMonths,
  deleteEmployee,
  getEmployee,
  updateEmployee,
  getMonthByEmployee,
} from '../../api/api';

// Initial State

const initialState = {
  loading: false,
  employees: [],
  employee: {},
};

// Provider Component

export const SatnicaState = ({ children }) => {
  const [state, dispatch] = useReducer(contextusReducer, initialState);

  const clearEmployees = () => {
    dispatch({ type: 'CLEAR_EMPLOYEES' });
  };

  return (
    <GlobalContext.Provider
      value={{
        displayEmployees,
        clearEmployees,
        addEmployee,
        addWorkingMonths,
        deleteEmployee,
        getEmployee,
        updateEmployee,
        getMonthByEmployee,
        dispatch,
        employees: state.employees,
        employee: state.employee,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
