import React, { useContext, useEffect, useState } from 'react';
import Login from '../components/Login';
import { Link } from 'react-router-dom';
import { auth } from '../config/config';
import authContext from '../contexts/auth/authContext';
import { useNavigate } from 'react-router-dom';
import globalContext from '../contexts/satnica/globalContext';
import Loader from '../components/Loader';

const LoginPage = () => {
  const AuthContext = useContext(authContext);
  const GlobalContext = useContext(globalContext);

  const { clearEmployees } = GlobalContext;

  const { loading, user, dispatch, smallLoading } = AuthContext;

  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      /* dispatch({ type: 'SET_LOADING', payload: true }); */

      if (user && !loading) {
        dispatch({ type: 'SET_USER', payload: user });
        navigate('/dashboard');
      } else {
        dispatch({ type: 'CLEAR_USER' });

        clearEmployees();
      }
    });

    return unsubscribe;
  }, [user, auth]);

  if (!user && loading) {
    return <p>loading....</p>;
  } else
    return (
      <div className='flex mx-auto flex-col py-12 px-4 sm:px-6 lg:px-8 md:w-[30rem]'>
        <div className='mb-10'>
          <h2
            className='mt-6 text-center text-3xl font-extrabold text-gray-900'
            style={{ fontFamily: 'sans-serif' }}
          >
            Login
          </h2>
          <div>{smallLoading && <Loader />}</div>
        </div>
        <Login />
        {/* <p className='text-center text-sm text-gray-600 mt-5'>
          "Nemate račun?
          <Link
            to='/register'
            className='font-medium text-blue-600 hover:text-blue-500'
          >
            Registriraj se
          </Link>
        </p> */}
      </div>
    );
};

export default LoginPage;
