import React from 'react';
import { Link } from 'react-router-dom';

const Menu = () => {
  return (
    <nav className='menu  bg-gray-200'>
      <ul className='list-none flex items-center p-1'>
        <li>
          <Link to='/dashboard'>
            <div
              className='py-1 px-5  border  mr-3 text-sm '
              style={{ borderRight: '1px solid #fff' }}
            >
              <i className='fa-solid fa-users-line mr-3'></i>
              <span className='font-semibold'>ZAPOSLENICI</span>
            </div>
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Menu;
