import React from 'react';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className='bg-gray-300 text-black py-2 text-center'>
      <div className='container mx-auto'>
        <p>&copy; {currentYear} Satnica . All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
