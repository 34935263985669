import React from 'react';

const DeleteConfirmationModal = ({ isOpen, onCancel, onConfirm }) => {
  const modalClasses = isOpen
    ? 'fixed inset-0 flex items-center justify-center z-10'
    : 'hidden';

  return (
    <div
      className={`${modalClasses} bg-black bg-opacity-50`}
      onClick={onCancel}
    >
      <div
        className='bg-white p-4 rounded shadow-md'
        onClick={(e) => e.stopPropagation()}
      >
        <p className='text-lg font-bold mb-4'>
          Da li ste sigurni da želite obrisati radnika?
        </p>
        <div className='flex justify-end'>
          <button
            className='bg-red-500 text-white px-4 py-2 mr-2 rounded hover:bg-red-600'
            onClick={onConfirm}
          >
            Ok
          </button>
          <button
            className='bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400'
            onClick={onCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationModal;
