import moment from 'moment';
import React, { useContext, useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import authContext from '../contexts/auth/authContext';
import globalContext from '../contexts/satnica/globalContext';
import localization from 'moment/locale/sr';

const PrintTable = ({
  days,
  leaveTotals,
  monthHours,
  clickPrint,
  selectedMonth,
  calculateDayHours,
  totalMonthHours,
}) => {
  const GlobalContext = useContext(globalContext);

  const { employee, dispatch } = GlobalContext;

  const AuthContext = useContext(authContext);

  const { user } = AuthContext;
  const componentRef = useRef();

  moment.updateLocale('sr', localization);

  const printHandeler = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Userdata',
    /*  onAfterPrint: () => alert('Uspjepno kreiranje printa'), */
  });

  useEffect(() => {
    if (clickPrint) {
      printHandeler();
    }
  }, [clickPrint]);

  const formatSelectedMonth = (selectedMonth) => {
    const formattedMonth = moment(selectedMonth)
      .locale('bs')
      .format('MMMM YYYY');

    return formattedMonth;
  };

  const formattedMonth = formatSelectedMonth(selectedMonth);

  return (
    <div ref={componentRef} className='mx-auto container'>
      <div>
        <h2 className='text-blue-500 text-center font-semibold p-3 text-lg mt-4'>
          Mjesečna evidencija radnog vremena
        </h2>
        <div className='my-1'>
          <p className='p-1 text-base '>
            Poslodavac:{' '}
            <span className='font-semibold'>{user.displayName}</span>
          </p>
          <p className='p-1 text-base'>
            Ime i Prezime:{' '}
            <span className='font-semibold'>
              {' '}
              {employee.firstName} {employee.lastName}
            </span>
          </p>
          <p className='p-1 text-base'>
            Mjesec:{' '}
            <span className='font-semibold capitalize'>{formattedMonth}</span>
          </p>
        </div>
      </div>
      <table className=' mt-3'>
        <thead className='bg-gray-600'>
          <tr className='bg-gray-600  text-black'>
            <th className='px-2'>Datum</th>
            <th className='px-2'>Dolazak</th>
            <th className='px-2'>Odlazak</th>
            <th className='px-2'>Ukup</th>
            <th className='px-2'>GO-PR</th>
            <th className='px-2'>GO</th>
            <th className='px-2'>Bol</th>
            <th className='px-2'>Pla</th>
            <th className='px-2'>Nep</th>
            <th className='px-2'>Služ</th>
            <th className='px-2'>Prek</th>
            <th className='px-2'>Dp</th>
          </tr>
        </thead>
        <tbody>
          {days?.map((day, index) => (
            <tr key={index} className='mt-2 px-2'>
              <td className={'border bg-gray-100'}>
                {new Date(day.date).getDate()}
                {'.' + day.dayOfWeek}
              </td>
              <td className='border px-2'>{day.startShift || ''}</td>
              <td className='border px-2'>{day.endShift || ''}</td>
              <td className='border px-2'>{calculateDayHours(day)}</td>
              <td className='border px-2'>{day.preYearVacation || ''}</td>
              <td className='border px-2'>{day.vacation || ''}</td>
              <td className='border px-2'>{day.sickLeave || ''}</td>
              <td className='border px-2'>{day.paidLeave || ''}</td>
              <td className='border px-2'>{day.unpaidLeave || ''}</td>
              <td className='border px-2'>{day.bussinesTravelHours || ''}</td>
              <td className='border px-2'>{day.overTimeHours || ''}</td>
              <td className='border px-2'>{day.nationalHoliday || ''}</td>
            </tr>
          ))}
          <tr className='bg-gray-300  text-black'>
            <td className='border font-semibold px-2'>Ukupno:</td>
            <td className='border'></td>
            <td className='border'></td>
            <td className='border font-semibold'> {monthHours} </td>
            <td className='border font-semibold'>
              {leaveTotals.totalPreYearVacation}
            </td>
            <td className='border font-semibold'>
              {leaveTotals.totalVacation}
            </td>
            <td className='border font-semibold'>
              {leaveTotals.totalSickLeave}
            </td>
            <td className='border font-semibold'>
              {leaveTotals.totalPaidLeave}
            </td>
            <td className='border font-semibold'>
              {leaveTotals.totalUnpaidLeave}
            </td>
            <td className='border font-semibold'>
              {leaveTotals.totalBussinesTravelHours}
            </td>
            <td className='border font-semibold'>
              {leaveTotals.totalOverTimeHours}
            </td>
            <td className='border font-semibold'>
              {leaveTotals.totalNationalHoliday}
            </td>
          </tr>
        </tbody>
      </table>
      <div className='mt-4 flex justify-between'>
        <p className='my-2'>Potpis Radnika: ___________________</p>
        <p className='my-2'>Potpis Poslodavca:_________________</p>
      </div>
      <div className='mt-2 '>
        <div className='flex justify-between'>
          <p>
            Mjesečni radni sati:{' '}
            <span className='font-semibold mt-2'>
              {totalMonthHours + leaveTotals.totalOverTimeHours}{' '}
            </span>
          </p>
          <p>
            Ukupno Mjesečno:{' '}
            <span className='font-semibold'>
              {totalMonthHours +
                leaveTotals.totalSickLeave +
                leaveTotals.totalVacation +
                leaveTotals.totalPreYearVacation +
                leaveTotals.totalOverTimeHours +
                leaveTotals.totalNationalHoliday}
              {'  '}
            </span>
          </p>
        </div>

        <div className='flex wrap gap-2 mt-3'>
          {leaveTotals.totalSickLeave !== 0 && (
            <p>
              Bolovanje:{' '}
              <span className='font-semibold'>
                {leaveTotals.totalSickLeave}
              </span>
            </p>
          )}
          {leaveTotals.totalVacation !== 0 && (
            <p>
              Godišnji odmor:{' '}
              <span className='font-semibold'>
                {leaveTotals.totalVacation}
                {'  '}
              </span>
            </p>
          )}
          {leaveTotals.totalPreYearVacation !== 0 && (
            <p>
              Godišnji odmor iz prošle godine:{' '}
              <span className='font-semibold'>
                {leaveTotals.totalPreYearVacation}
                {'  '}
              </span>
            </p>
          )}
          {leaveTotals.totalPaidLeave !== 0 && (
            <p>
              Plaćeno odsustvo:{' '}
              <span className='font-semibold'>
                {leaveTotals.totalPaidLeave}
                {'  '}
              </span>
            </p>
          )}
          {leaveTotals.totalUnpaidLeave !== 0 && (
            <p>
              Neplaćeno odsustvo:{' '}
              <span className='font-semibold'>
                {leaveTotals.totalUnpaidLeave}
                {'  '}
              </span>
            </p>
          )}
          {leaveTotals.totalBussinesTravelHours !== 0 && (
            <p>
              Službeno putovanje:{' '}
              <span className='font-semibold'>
                {leaveTotals.totalBussinesTravelHours}
                {'  '}
              </span>
            </p>
          )}
          {leaveTotals.totalOverTimeHours !== 0 && (
            <p>
              Prekovremeni rad:{' '}
              <span className='font-semibold'>
                {leaveTotals.totalOverTimeHours}
                {'  '}
              </span>
            </p>
          )}
          {leaveTotals.totalNationalHoliday !== 0 && (
            <p>
              Državni praznik:{' '}
              <span className='font-semibold'>
                {leaveTotals.totalNationalHoliday}
                {'  '}
              </span>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default PrintTable;
