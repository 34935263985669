import React, { useContext } from 'react';
import alertContext from '../contexts/alert/alertContext';

const Alerts = () => {
  const AlertContext = useContext(alertContext);

  return (
    AlertContext.alerts.length > 0 &&
    AlertContext.alerts.map((alert) => (
      <div
        key={alert.id}
        className={`p-3 text-sm text-center ${alert.type}`}
        role='alert'
      >
        {alert.msg}
      </div>
    ))
  );
};

export default Alerts;
