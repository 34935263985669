import React, { useContext } from 'react';
import authContext from '../contexts/auth/authContext';
import Logo from './Logo';
import Navbar from './Navbar';

const Header = () => {
  const AuthContext = useContext(authContext);

  const { user } = AuthContext;
  return (
    <div className='border-b divide-slate-200 bg-[#eee]'>
      <div className='custom-container mx-auto'>
        <header className='flex justify-between items-center p-4'>
          <Logo />
          {user && <Navbar />}
        </header>
      </div>
    </div>
  );
};

export default Header;
