import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import authContext from '../contexts/auth/authContext';
import globalContext from '../contexts/satnica/globalContext';
import { useNavigate } from 'react-router-dom';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import alertContext from '../contexts/alert/alertContext';
import Loader from '../components/Loader';

const ZaposleniciTable = () => {
  const AlertContext = useContext(alertContext);

  const { setAlert } = AlertContext;

  const [input, setInput] = useState('');

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [employeeIdToDelete, setEmployeeIdToDelete] = useState(null);

  const handleDelete = () => {
    if (employeeIdToDelete) {
      deleteEmployee(user.uid, employeeIdToDelete, setAlert);
      setDeleteModalOpen(false);
    }
  };

  const GlobalContext = useContext(globalContext);
  const AuthContext = useContext(authContext);

  const { user } = AuthContext;
  const { employees, deleteEmployee, getEmployee, dispatch } = GlobalContext;

  const navigate = useNavigate();

  const onClickNavigateEdit = (currentUserUid, id) => {
    const fetchEmployee = async (id) => {
      const employee = await getEmployee(currentUserUid, id);

      dispatch({ type: 'DISPLAY_EMPLOYEE', payload: employee });
      navigate('/user/edit/' + id);
    };

    fetchEmployee(id);
  };

  const onClickEnterWorklog = (currentUserUid, id) => {
    const fetchEmployee = () => {
      navigate('/worklog/' + id);
    };

    fetchEmployee();
  };

  // Filter employees based on the input
  const filteredEmployees = employees.filter(
    (employee) =>
      employee.firstName.toLowerCase().includes(input.toLowerCase()) ||
      employee.lastName.toLowerCase().includes(input.toLowerCase())
  );

  return (
    <div className='w-3/4'>
      <div className='flex flex-col'>
        <label className='my-2'>Pretraži zaposlenika</label>
        <input
          onChange={(e) => setInput(e.target.value)}
          type='text'
          className={
            'appearance-none  w-[15rem] px-3 py-1 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 text-sm mb-5'
          }
        />
      </div>
      <div className='flex'>
        {employees?.length > 0 && (
          <table className='border-collapse border border-slate-400 table w-[80%]'>
            <thead>
              <tr>
                <th className='border border-slate-300 bg-blue-500 text-white font-medium p-1 px-2 w-[40%] '>
                  Prezime i Ime
                </th>
                <th className='border border-slate-300 bg-blue-500 text-white font-medium p-1 px-2 w-[30%]'>
                  Radno Mjesto
                </th>
                <th className='border border-slate-300 bg-blue-500 text-white font-medium p-1 px-2 w-[10%]'>
                  Izvještaj
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredEmployees?.map((item, index) => (
                <tr key={index}>
                  <td className='border border-slate-300 bg-blue-100 p-2 px-2 group'>
                    <div className='flex justify-between items-center'>
                      <div
                        className='flex items-baseline cursor-pointer'
                        onClick={() => onClickEnterWorklog(user.uid, item.id)}
                      >
                        <i className='fa-sharp fa-solid fa-user mr-2'></i>
                        {`${item.firstName} ${item.lastName}`}
                      </div>

                      <div className='invisible group-hover:visible'>
                        <i
                          onClick={() => onClickNavigateEdit(user.uid, item.id)}
                          className='fa-sharp fa-solid fa-pen-to-square mr-2 cursor-pointer hover:text-blue-700'
                        ></i>
                        <i
                          className='far fa-trash-alt cursor-pointer hover:text-blue-700'
                          /* onClick={() => deleteEmployee(user.uid, item.id)} */
                          onClick={() => {
                            setEmployeeIdToDelete(item.id); // Set the employee for deletion
                            setDeleteModalOpen(true); // Open the modal
                          }}
                        ></i>
                      </div>
                    </div>
                  </td>
                  <td className='border border-slate-300 bg-blue-100 p-1 px-2'>
                    {item.department}
                  </td>
                  <th className='border border-slate-300 bg-blue-100 p-1 px-2'>
                    -
                  </th>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        <div>
          <Link to='/user'>
            <div
              className={`bg-blue-500 p-1 px-3 text-white flex items-center text-sm w-full ${
                employees.length > 0 && 'ml-3'
              }`}
            >
              <i className='fa-sharp fa-solid fa-user-plus mr-2'></i>
              <span>DODAJ ZAPOSLENIKA</span>
            </div>
          </Link>
        </div>
      </div>
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        onCancel={() => setDeleteModalOpen(false)}
        onConfirm={handleDelete}
      />
    </div>
  );
};

export default ZaposleniciTable;
