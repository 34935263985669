import { useContext } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import Menu from '../components/Menu';
import authContext from '../contexts/auth/authContext';

const PrivateRoutes = () => {
  const AuthContext = useContext(authContext);
  const { user, loading } = AuthContext;

  return user && !loading ? (
    <>
      <div className='mb-5' style={{ border: '1px solid #eee' }}>
        <h2
          className='text-2xl text-center py-4 text-black font-semibold'
          style={{ borderBottom: '1px solid #eee' }}
        >
          {user.displayName}
        </h2>
        <Menu />
        <Outlet />
      </div>
    </>
  ) : (
    <Navigate to='/' />
  );
};

export default PrivateRoutes;
