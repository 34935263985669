const authReducer = (state, action) => {
  switch (action.type) {
    case 'SET_USER':
      return {
        ...state,
        user: action.payload,
        loading: false,
        isAuthenticated: true,
      };
    case 'CLEAR_USER':
      return { ...state, user: null, loading: false, smallLoading: false };
    case 'SET_LOADING':
      return { ...state, loading: action.payload };
    case 'SET_SMALL_LOADING':
      return { ...state, smallLoading: action.payload };
    default:
      return state;
  }
};

export default authReducer;
